import React from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 350
  },
  buttonArea: {
    paddingBottom: "0px !important"
  },
  button: {
    minWidth: 80
  }
}));

const MessageDialog = props => {
  const classes = useStyles();
  const { open, title, body, onClose, foot } = props;
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: body }} />
        <DialogActions className={classes.buttonArea}>
          <Button
            className={classes.button}
            onClick={() => onClose(true)}
            color="primary"
            variant="outlined"
            autoFocus
          >
            {foot ? foot : "閉じる"}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default MessageDialog;
