import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import AppPrimaryArea from '../../components/AppPrimaryArea';
import AppTable from '../../components/AppTable';
import useAppContext from '../../hooks/useAppContext';
import { objectComparator, DateRadio, dateToTimestamp } from '../../data-helper';
import AppForm from '../../components/AppForm';

const dateRangeSchema = yup.array()
  .test('dateRange', '正しい日時を入力してください', (value) => {
    if (value && value[0] && value[1] && dateToTimestamp(value[0]) > dateToTimestamp(value[1])) {
      return false;
    }
    return true;
  });

const formFields1 = [
  { name: "name", label: "プロジェクト名称", type: "text", required: true, size: 2 },
  { name: "method", label: "プロジェクト方法論", type: "text", required: true },
  { name: "administrator", label: "運営管理者", type: "text", required: true },
  { name: "creator", label: "活動の実施者", type: "text", required: true },
  { name: "dataAdministrator", label: "データ管理責任者", type: "text", required: true },
  { name: "monitoring", label: "モニタリング担当者", type: "text", required: true },
  { name: "date", label: "日付", type: "radio", size: 0.9 },
];

const formFields2 = [
  { name: "daterange", label: "", type: "daterange", schema: dateRangeSchema },
];

const tableFields = [
  { name: "constructName", label: "現場名", type: "text", xs: true },
];

const breadcrumbs = [
];

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative"
  },
  dateRangePosition: {
    position: "absolute",
    top: 256,
    width: 317,
    left: 795
  }
}));

const JCreditReportPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const selectList = { date: DateRadio };
  // need to separate data and formWatchData to avoid data propagation of AppFrom
  const [data] = useState({
    name: "ポルトランドセメント配合量の少ないコンクリートの打設",
    method: "EN-S-040",
    administrator: app.loginInfo.user.corporateName,
    creator: app.loginInfo.user.corporateName,
    dataAdministrator: app.loginInfo.user.corporateName,
    monitoring: app.loginInfo.user.corporateName,
    date: "1"
  });
  const [dateWatchData, setDateWatchData] = useState(null);
  const [formWatchData, setFormWatchData] = useState(null);
  const [constructs, setConstructs] = useState([]);
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    const query = async () => {
      try {
        app.setIsLoading(true);
        const params = {discloseConstruct: true};
        const res = await app.http().get('/master/construct', params);
        res.sort(objectComparator('buildingConfirmationDate'));
        setConstructs(res);
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeSelection = (selected) => setSelection(selected);

  const watchFormValues = (values) => {
    setFormWatchData(values);
    if (values.date === "1") {
      setDateWatchData(null);
    }
  };

  const showReport = () => {
    if (selection.length === 0) {
      return true
    }

    if (formWatchData.date === "0") {
      const dateArray = dateWatchData?.daterange;
      if (dateArray && dateArray[0] && dateArray[1] && dateToTimestamp(dateArray[0]) > dateToTimestamp(dateArray[1])) {
        return true;
      }
    }
    return false;
  }

  const handleSubmit = () => {
    let dateRange = [];
    if (formWatchData.date === "0") {
      const dateArray = dateWatchData.daterange;
      if (dateArray) {
        dateRange = [dateToTimestamp(dateArray[0]), dateToTimestamp(dateArray[1])];
      }
    }

    // pass parameters by session storage
    const params = {
      constructIDs: selection.map((d) => d._id),
      managementInfo: formWatchData,
      dateRange
    };
    sessionStorage.setItem('JCreditReportSubmitData', JSON.stringify(params));
    navigate(`./view`);
  };

  return (
    <div className={classes.root}>
      <AppPrimaryArea title="J-クレジット申請レポート" breadcrumbs={breadcrumbs}>
      <Container component="main" maxWidth="md" sx={{ py: 2 }}>
        <AppForm formFields={formFields1} data={data} selectList={selectList} watch={watchFormValues} />
        {formWatchData?.date === "0" &&
          <div className={classes.dateRangePosition}><AppForm formFields={formFields2} watch={setDateWatchData} columns={{ xs: 1, md: 1 }} /></div>
        }

        <AppTable tableFields={tableFields} data={constructs} selection="select" onChangeSelection={handleChangeSelection} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', my: 2 }}>
          <Box>
            <Button variant="contained" color="info" onClick={handleSubmit} disabled={showReport()}>
              レポート表示
            </Button>
          </Box>
        </Box>
      </Container>
      </AppPrimaryArea>
    </div>
  );
};

export default JCreditReportPage;
