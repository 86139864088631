import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import useAppContext from '../../common/hooks/useAppContext';
import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import Construct from './components/Construct';
import { Presence, Prefecture, Disclosure } from '../../common/data-helper';

const breadcrumbs = [
  { name: '現場一覧', path: '../manage-construct' },
];

const useStyles = makeStyles(theme => ({
  root: {}
}));

const ConstructPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    corporateId: app.loginInfo.user.corporateId,
    corporateName: app.loginInfo.user.corporateName,
    plannedVolume: 0,
    disclosure: 'Private',
  });
  const [readOnly, setReadOnly] = useState(true);
  const [concretePlants, setConcretePlants] = useState([]);
  const [selectList, setSelectList] = useState({
    prefecture: Prefecture,
    localPrefecture: [{ label: '全て', value: '全て' }, ...Prefecture],
    particiationStatus: Presence,
    disclosure: Disclosure,
    plantIds: concretePlants,
    allPlantIds: concretePlants,
  });

  const handlers = {
    localPrefecture: {
      onChange: (event, values) => {
        const data = {
          ...values,
          localPrefecture: event.target.value,
        };
        setData(data);
        setSelectList({...selectList, plantIds: event.target.value === '全て' ? concretePlants : concretePlants.filter(c => c.hint.includes(event.target.value))});
      }
    }
  };

  useEffect(() => {
    const query = async () => {
      try {
        app.setIsLoading(true);
        const result = await app.http().get('/master/concrete-plant');
        const list = result.map((d) => ({ label: d.plantName, value: d._id, hint: d.address }));
        setConcretePlants(list);
        setSelectList({...selectList, plantIds: list, allPlantIds: list})
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (params.constructId && params.constructId !== 'new') {
      const query = async () => {
        try {
          app.setIsLoading(true);
          const result = await app.http().get(`/master/construct/${params.constructId}`);
          setData(result);
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      };
      query();
    } else {
      setReadOnly(false);
    }
  }, [params.constructId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditItem = (edit) => () => {
    if (params.constructId === 'new') {
      navigate('../manage-construct');
    } else {
      setReadOnly(!edit);
    }
  };

  const handleRemoveItem = () => {
    app.showConfirmDialog('削除確認', `${data.constructName} を削除しようとしています。</br>実行してよろしいですか？`, async (result) => {
      if (result) {
        try {
          app.setIsLoading(true);
          await app.http().delete(`/master/construct/${data._id}`);
          navigate('../manage-construct');
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      }
    });
  };

  const handleManageCompoundPlan = () => navigate('./compound-plan');

  const handleSubmit = async (values) => {
    try {
      app.setIsLoading(true);
      const formData = new FormData();
      const data = { ...values };
      delete data._attachments;
      delete data.localPrefecture;
      formData.append("params", JSON.stringify(data));
      formData.append('removeBuildingConfirmationEvidence', JSON.stringify(values._attachments.buildingConfirmationEvidence.removeFiles));
      values._attachments.buildingConfirmationEvidence.newFiles.forEach((file) => formData.append('newBuildingConfirmationEvidence', file));
      await app.http(true).post('/master/construct', formData);
      if (params.constructId === 'new') {
        navigate('../manage-construct');
      } else {
        const result = await app.http().get(`/master/construct/${params.constructId}`);
        setData(result);
        setReadOnly(true);
      }
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  // const [watchData, setWatchData] = useState(null);
  // const watch = (values) => {
  //   setWatchData(values);
  // };
  // const onHandlePostalCodeAdornment = async (event) => {
  //   const zipcode = watchData.postalCode.trim();
  //   if (zipcode.length === 8) {
  //     try {
  //       app.setIsLoading(true);
  //       const res = await app.http().get(`/utility/zip/${zipcode}`);
  //       const data = {
  //         ...watchData,
  //         postalCode: res.postalCode,
  //         prefecture: res.prefecture,
  //         address: res.address,
  //       };
  //       setData(data);
  //     } catch (err) {
  //       app.handleHttpError(err);
  //     } finally {
  //       app.setIsLoading(false);
  //     }
  //   }
  // };
  // const adornment = {
  //   postalCode: <Button variant="outlined" onClick={onHandlePostalCodeAdornment} sx={{ px: 0, minWidth: 100, display: 'none' }}>住所検索</Button>
  // };

  const submitActions = [
    { name: params.constructId === 'new' ? '登録' : '更新', handler: handleSubmit },
    { name: 'キャンセル', handler: handleEditItem(false), skipValidation: true, color: 'primary', variant: 'outlined' }
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={'現場情報' + (data.constructName ? `　${data.constructName}` : '')} breadcrumbs={breadcrumbs}>
        {readOnly && (
          <div>
            <Button size="large" variant="contained" color="info" onClick={handleEditItem(true)}>
              編集
            </Button>
            &nbsp;&nbsp;
            <Button size="large" variant="outlined" color="primary" onClick={handleRemoveItem}>
              削除
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button size="large" variant="outlined" color="secondary" onClick={handleManageCompoundPlan}>
              配合計画の管理
            </Button>
          </div>
        )}
        {!readOnly && <Construct data={data} selectList={selectList} readOnly={false} submitActions={submitActions} handlers={handlers}/>}
      </AppPrimaryArea>
      <Container component="main" maxWidth="md" sx={{ px: 2, my: 2 }}>
        {readOnly && <Construct data={data} selectList={selectList} />}
      </Container>
    </div>
  );
};

export default ConstructPage;
