import dayjs from 'dayjs';
import Encoding from 'encoding-japanese';
import { timestampToDate } from './data-helper';

const separator = '\t';

const toLineData = (d) => {
  const str = Array.isArray(d) ? d.join(separator) : (d ? d : '');
  // eslint-disable-next-line
  return str.replace(/\u000A/g, '');
};

const downloadTsv = (lines, prefix, encoding = 'UTF8', dateRange) => {
  const stringData = lines.join('\n');
  const codeArray = Encoding.stringToCode(stringData);
  const encodedArray = Encoding.convert(codeArray, encoding);
  const uInt8Array = new Uint8Array(encodedArray);
  const file = new Blob([uInt8Array], { type: 'text/tab-separated-values' });
  const downLoadLink = document.createElement('a');
  if (prefix === 'JCreditReport' && dateRange.length > 0) {
    downLoadLink.download = `${prefix}_[${dateRange[0] ? timestampToDate(dateRange[0]).format('YYYY-MM-DD') : ''}_to_${dateRange[1] ? timestampToDate(dateRange[1]).format('YYYY-MM-DD') : ''}]_${dayjs().format('YYYY-MM-DD_HH-mm-ss')}.tsv`;
  } else {
    downLoadLink.download = prefix + '_' + dayjs().format('YYYY-MM-DD_HH-mm-ss') + '.tsv';
  }
  downLoadLink.href = URL.createObjectURL(file);
  downLoadLink.dataset.downloadurl = ['text/tab-separated-values', downLoadLink.download, downLoadLink.href].join(':');
  downLoadLink.click();
};

const readTextFile = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const codes = new Uint8Array(e.target.result);
      const encoding = Encoding.detect(codes);
      const unicodeString = Encoding.convert(codes, {
        to: 'unicode',
        from: encoding,
        type: 'string'
      });
      resolve(unicodeString);
    };
    reader.readAsArrayBuffer(file);
  });
};

const parseTsv = (text) => {
  const fields = [];
  const texts = text.split(/\r\n|\r|\n/);
  if (texts.length < 2) {
    return null;
  }
  const labels = texts[0].split('\t');
  const names = texts[1].split('\t');
  for (let i = 0; i < labels.length; i++) {
    fields.push({ name: names[i], label: labels[i], type: 'text' });
  }
  const data = [];
  for (let i = 2; i < texts.length; i++) {
    if (!texts[i] || texts[i].trim().length === 0) {
      continue;
    }
    const values = texts[i].split('\t');
    const result = {};
    for (let j = 0; j < names.length; j++) {
      let value = values[j];
      if (value.startsWith('=')) {
        value = value.substring(1);
      }
      if (value.startsWith('"') && value.endsWith('"')) {
        value = value.slice(1, -1);
      }
      result[names[j]] = value;
    }
    data.push(result);
  }
  return { fields, data };
};

export {
  toLineData,
  downloadTsv,
  parseTsv,
  readTextFile,
};
