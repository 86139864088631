import { useState, useRef } from 'react';
import { Remove, Add } from '@mui/icons-material';
import { AG_GRID_LOCALE_JP } from "@ag-grid-community/locale";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "./styles.css";

const ConcretePlantGrid = ({ defaultRowData, setRowData, setSelectedRowData }) => {
  const gridRef = useRef(null);
  const localeText = AG_GRID_LOCALE_JP;

  const onCellValueChanged = () => {
    let nowData = [];
    gridRef.current.api.forEachNode(node => nowData.push(node.data));
    setRowData([...nowData]);
  };
  const onSelectionChanged = () => {
    // let nowData = [];
    // gridRef.current.api.forEachNode(node => nowData.push(node.data));
    // setRowData([...nowData]);
  };
  const [columnDefs] = useState([
    // {
    //   headerName: '',
    //   field: 'id',
    //   width: 40,
    //   editable: true,
    //   sortable: false, // 並べ替えを無効化
    //   filter: false, // フィルターを有効化
    //   cellRenderer: (params) => (
    //     <div style={{ display: 'flex', alignItems: 'center', width: 40, height: 40, marginLeft: -16, paddingLeft: 8, cursor: "pointer" }} onClick={() => deleteAddRow(params)}>
    //       {params.node.isRowPinned() ? <Add color="info" /> : <Remove color="error" />}
    //     </div>
    //   ),
    // },
    { headerName: '工場ID', field: '_id', editable: (o) => !o.node.isRowPinned(), sortable: false, filter: true },
    { headerName: '企業ID', field: 'corporateId', editable: (o) => !o.node.isRowPinned(), sortable: false, filter: true },
    { headerName: '製造会社名', field: 'plantName', editable: (o) => !o.node.isRowPinned(), sortable: false, filter: true },
    { headerName: '製造会社住所', field: 'address', editable: (o) => !o.node.isRowPinned(), sortable: false, filter: true },
    { headerName: '認証番号', field: 'certificateNumber', editable: (o) => !o.node.isRowPinned(), sortable: false, filter: true },
    { headerName: '認証に関わるJIS番号', field: 'JISnumber', editable: (o) => !o.node.isRowPinned(), sortable: false, filter: true },
    { headerName: '認証書添付', field: 'JISEvidence', editable: (o) => !o.node.isRowPinned(), sortable: false, filter: true },
  ]);

  // 行を削除と追加する関数
  const deleteAddRow = (params) => {
    let nowData = [];
    gridRef.current.api.forEachNode(node => nowData.push(node.data));
    if (params.node.isRowPinned()) {
      gridRef.current.api.setGridOption('rowData', [...nowData, {}]);
      setRowData([...nowData, {}]);
    } else {
      const updatedRowData = nowData.filter((_, index) => index !== params.node.rowIndex);
      gridRef.current.api.setGridOption('rowData', updatedRowData);
      setRowData(updatedRowData);
    }
  };


  return (
    <>
    <div className="ag-theme-quartz" style={{ height: "calc(100vh - 400px)" }}>
      <AgGridReact
        ref={gridRef}
        rowSelection={{mode: "multiRow"}}
        localeText={localeText}
        columnDefs={columnDefs}
        rowData={defaultRowData}
        defaultColDef={{
          resizable: true, // 列のサイズ変更のみ有効にする
          editable: true,
          sortable: false, // グリッド全体の並べ替えを無効化
          suppressMovable: true, // 列のドラッグ＆ドロップ移動を無効化
          filter: true, // デフォルトで全列にフィルターを有効化
        }}
        onCellValueChanged={onCellValueChanged}
        onSelectionChanged={onSelectionChanged}
        // pinnedBottomRowData={[{}]}
      />
    </div>
    <div style={{background: "#000", height: 20}}></div>
    </>
  );
};

export default ConcretePlantGrid;
