import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as OTPAuth from 'otpauth';

import useAppContext from '../common/hooks/useAppContext';
import AppForm from '../common/components/AppForm';

const useStyles = makeStyles(theme => ({
  background: {
    height: "100vh",
    background: "rgb(248, 250, 251)",
    overflow: "auto"
  },
  space6: {
    marginTop: theme.spacing(6),
  },
  loginContainer: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    background: "#fff",
    padding: "24px 150px",
    borderRadius: 8
  },
  loginTitle: {
    fontSize: "24px !important",
    fontWeight: "700 !important",
    marginBottom: "24px !important"
  },
}));

const loginFields = [
  { name: "loginId", label: "ログインID", type: "text", required: true, },
  { name: "password", label: "パスワード", type: "password", required: true, },
];

const LoginPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      app.setIsLoading(true);
      const res = await app.http().post('/auth', values);
      if (res.ChallengeName) {
        localStorage.setItem('loginId', values.loginId);
        localStorage.setItem('password', values.password);
        navigate("/verify-code");
      } else {
        let totp = new OTPAuth.TOTP({
          issuer: "Kajima",
          label: "Kajima",
          secret: res.SecretCode
        });
        let uri = totp.toString();
        totp = OTPAuth.URI.parse(uri);
        localStorage.setItem('qrcodeStr', totp);
        localStorage.setItem('SecretCode', res.SecretCode);
        localStorage.setItem('Session', res.Session);
        localStorage.setItem('loginId', values.loginId);
        localStorage.setItem('password', values.password);
        navigate("/setup-mfa");
      }
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const submitActions = [
    { name: 'ログイン', handler: handleSubmit, fullWidth: true },
  ];

  return (
    <div className={classes.background}>
      <div className={classes.space6} />
      <Container component="main" maxWidth="sm" className={classes.loginContainer}>
        <div style={{padding: "24px 100px"}}>
          <Typography align="center" className={classes.loginTitle}>
          CO2見える化プラットフォーム
          </Typography>
          <AppForm formFields={loginFields} submitActions={submitActions}  columns={{ xs: 1, md: 1 }} />
        </div>
      </Container>
    </div>
  );
};

export default LoginPage;
