import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, FormControlLabel, MenuItem, Select, RadioGroup, Radio, TextField, Typography, Button } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import Attachment from './Attachment';

import { Autocomplete, Checkbox, Chip } from '@mui/material';
import Unchecked from '@mui/icons-material/CheckBoxOutlineBlank';
import Checked from '@mui/icons-material/CheckBox';

import { valueToLabel, formatDate, formatDateTime } from '../data-helper';
import dayjs from 'dayjs';

const useStyles = makeStyles(theme => ({
  label: {
    color: '#666666',
  },
  labelReadOnly: {
    color: '#666666',
    borderBottom: 'solid #7697A9 1px',
  },
  selectText: {
    color: '#333333'
  },
  required: {
    color: '#00ACFF',
  },
  error: {
    color: 'red',
  },
  text: {
    width: 'calc(100% - 28px)',
    padding: '8.5px 14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  autocomplete: {
    '&:hover': {
      background: theme.palette.secondary.light
    }
  }
}));

const AppFieldWrapper = ({ label, error, required, children, readonly, adornment, unit }) => {
  const classes = useStyles();
  return (
    <div className={classes.fieldWrapper}>
      <Typography variant="body2" className={readonly ? classes.labelReadOnly : classes.label}>
        {label}
        &nbsp;&nbsp;
        {(required && !unit) && <span className={classes.required}>*</span>}
      </Typography>
      <Box sx={{ display: 'flex' }}>
        {children}
        {adornment && adornment}
      </Box>
      {error &&
        <Typography variant="body2" className={classes.error}>
          {error}
        </Typography>
      }
    </div>
  );
};

const ReadOnlyField = ({ label, value, format }) => {
  const classes = useStyles();
  let displayText = format ? format(value) : value;
  if (!displayText) {
    // displayText = '(指定なし)';
    displayText = '';
  }
  return (
    <AppFieldWrapper label={label} readonly={true}>
      <div className={classes.text}>{displayText}</div>
    </AppFieldWrapper>
  );
};

const AppText = ({ name, label, value, error, required, readonly, onChange, onBlur, format, inputProps, adornment, offAutoComplete }) => {
  const sx = { input: { color: '#333333' } };
  if (readonly) {
    return <ReadOnlyField label={label} value={value} format={format} />;
  }
  return (
    <AppFieldWrapper label={label} required={required} error={error} adornment={adornment}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        name={name}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        inputProps={{
          ...inputProps,
          autoComplete: offAutoComplete ? "off" : "",
        }}
        sx={sx}
      />
    </AppFieldWrapper>
  );
};

const AppNumber = ({ name, label, value, error, required, readonly, onChange, onBlur, format, inputProps, unit }) => {
  const sx = { input: { color: '#333333' } };
  if (readonly) {
    return <ReadOnlyField label={label} value={value} format={format} />;
  }
  return (
    <AppFieldWrapper label={label} required={required} error={error} unit={unit}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        type="number"
        name={name}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        inputProps={inputProps}
        sx={sx}
      />
    </AppFieldWrapper>
  );
};

const AppPassword = ({ name, label, value, error, required, readonly, onChange, onBlur, format, inputProps }) => {
  const sx = { input: { color: '#333333' } };
  if (readonly) {
    return <ReadOnlyField label={label} value={value} format={format} />;
  }
  return (
    <AppFieldWrapper label={label} required={required} error={error}>
      <TextField
        type="password"
        variant="outlined"
        size="small"
        fullWidth
        name={name}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        inputProps={inputProps}
        sx={sx}
      />
    </AppFieldWrapper>
  );
};
const AppSelect = ({ name, label, value, error, required, readonly, onChange, onBlur, selectList }) => {
  const classes = useStyles();
  const sx = { div: { color: '#333333' } };
  if (readonly) {
    return <ReadOnlyField label={label} value={value} format={(value) => valueToLabel(selectList, value)} />;
  }
  return (
    <AppFieldWrapper label={label} required={required} error={error}>
      <Select
        size="small"
        fullWidth
        name={name}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        value={value ?? null}
        sx={sx}
      >
        {selectList && selectList.map((s, i) => (
          <MenuItem key={i} value={s.value}>
            <span className={classes.selectText}>{s.label}</span>
          </MenuItem>
        ))}
      </Select>
    </AppFieldWrapper>
  );
};

const AppRadio = ({ name, label, value, error, required, readonly, onChange, onBlur, selectList }) => {
  if (readonly) {
    return <ReadOnlyField label={label} value={value} format={(value) => valueToLabel(selectList, value)} />;
  }
  return (
    <AppFieldWrapper label={label} required={required} error={error}>
      <RadioGroup row name={name} value={value} onChange={onChange}>
        {selectList && selectList.map((s, i) => (
          <FormControlLabel key={i} value={s.value} control={<Radio />} label={s.label} />
        ))}
      </RadioGroup>
    </AppFieldWrapper>
  );
};

const dateFormat = 'YYYY/MM/DD';
const AppDate = ({ name, label, value, error, required, readonly, onChange, onBlur }) => {
  const handleChange = (value, text) => {
    const date = text ? dayjs(text, dateFormat, true) : value;
    onChange(date);
  };
  if (readonly) {
    return <ReadOnlyField label={label} value={value} format={formatDate} />;
  }
  return (
    <AppFieldWrapper label={label} required={required} error={error}>
      <DatePicker
        inputFormat={dateFormat}
        mask="____/__/__"
        size="small"
        fullWidth
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
      />
    </AppFieldWrapper>
  );
};

const dateTimeFormat = 'YYYY/MM/DD HH:mm';
const AppDateTime = ({ name, label, value, error, required, readonly, onChange, onBlur }) => {
  const handleChange = (value, text) => {
    const date = text ? dayjs(text, dateTimeFormat, true) : value;
    onChange(date);
  };
  if (readonly) {
    return <ReadOnlyField label={label} value={value} format={formatDateTime} />;
  }
  return (
    <AppFieldWrapper label={label} required={required} error={error}>
      <DateTimePicker
        inputFormat={dateTimeFormat}
        mask="____/__/__ __:__"
        size="small"
        fullWidth
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
      />
    </AppFieldWrapper>
  );
};

const AppAttachment = ({ name, label, files, currentNewFiles, currentRemoveFiles, error, required, readonly, onChange, onBlur }) => {
  if (readonly) {
    return (
      <AppFieldWrapper label={label} readonly={true}>
        <Box sx={{ mt: 1 }}>
          <Attachment files={files} currentNewFiles={currentNewFiles} currentRemoveFiles={currentRemoveFiles} readonly={true} />
        </Box>
      </AppFieldWrapper>
    );
  }
  return (
    <AppFieldWrapper label={label} required={required} error={error} readonly={true}>
      <Box sx={{ mt: 1 }}>
        <Attachment files={files} currentNewFiles={currentNewFiles} currentRemoveFiles={currentRemoveFiles} onChange={onChange} />
      </Box>
    </AppFieldWrapper>
  );
};

const handleChangeMultipleSelect = (onChange) => (event, value) => {
  const appValue = value.map((d) => d.value);
  onChange(appValue);
};
const filterOptions = (options, state) => options.filter((option) => {
  const text = option.label + (option.hint ? option.hint : '');
  return !state.inputValue || text.includes(state.inputValue);
});
const AppMultipleSelect = ({ name, label, value, error, required, readonly, onChange, onBlur, selectList, allSelectList }) => {
  const classes = useStyles();
  const [innerValue, setInnerValue] = useState([]);
  useEffect(() => {
    const appValue = value ? value : [];
    const equal = innerValue.length === appValue.length && innerValue.every((d, i) => d.value === appValue[i]);
    if (!equal && allSelectList.length > 0) {
      const values = value.map((v) => allSelectList.find((d) => d.value === v));
      setInnerValue(values);
    } else {
      setInnerValue([]);
    }
  }, [value, allSelectList]); // eslint-disable-line react-hooks/exhaustive-deps
  if (readonly) {
    return (
      <AppFieldWrapper label={label} readonly={true}>
        <Box sx={{ my: 1 }}>
          {innerValue.map((d) => <Chip key={d.value} label={d.label} sx={{ mr: 1, bgcolor: "#DFEFFF" }} />)}
        </Box>
      </AppFieldWrapper>
    );
  }
  return (
    <AppFieldWrapper label={label} required={required} error={error}>
      <Autocomplete
        multiple
        fullWidth
        value={innerValue}
        options={selectList}
        filterOptions={filterOptions}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        noOptionsText="選択された都道府県ではプラントが存在しません"
        renderTags={(value, getTagProps) =>
          value.map((e, index) => (
            <Chip
              variant="outlined"
              key={index}
              style={{backgroundColor: "#DFEFFF"}}
              label={e.label}
              {...getTagProps({index})}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} style={selected ? {background: "#DFEFFF"} : {}} className={classes.autocomplete}>
            <Checkbox icon={<Unchecked fontSize="small" />} checkedIcon={<Checked fontSize="small" />} checked={selected} />
            <span style={{ marginLeft: 8, marginRight: 16 }}>{option.label}</span>
            {option.hint && <span style={{ opacity: 0.6 }}>{option.hint}</span>}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label="" placeholder="キーワード検索" />
        )}
        onChange={handleChangeMultipleSelect(onChange)}
      />
    </AppFieldWrapper>
  );
};

const AppDateRange = ({ label, error, required, onChange, onBlur }) => {
  const [innerValue, setInnerValue] = useState([null, null]);
  const handleChange = (value, text, index) => {
    const date = text ? dayjs(text, dateFormat, true) : value;
    innerValue[index] = date;
    setInnerValue([...innerValue]);
    onChange(innerValue);
  };

  return (
    <AppFieldWrapper label={label} required={required} error={error}>
      <DatePicker
        inputFormat={dateFormat}
        mask="____/__/__"
        size="small"
        fullWidth
        value={innerValue[0]}
        onChange={(value, text) => handleChange(value, text, 0)}
        onBlur={onBlur}
        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
      />
      <div style={{display: "flex", alignItems: "center", margin: "0 4px"}}>~</div>
      <DatePicker
        inputFormat={dateFormat}
        mask="____/__/__"
        size="small"
        fullWidth
        value={innerValue[1]}
        onChange={(value, text) => handleChange(value, text, 1)}
        onBlur={onBlur}
        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
      />
    </AppFieldWrapper>
  );
};

const AppUpload = ({ label, required, file, setFile, accept, multiple }) => {
  const fileInputRef = useRef(null);
  const handleOpenFile = () => fileInputRef.current.click();

  const [fileName, setFileName] = useState("");
  const handleFile = async (event) => {
    if (event.target.files.length > 0) {
      setFile(event.target.files)
      const fileNameList = []
      for (const file of event.target.files) {
        fileNameList.push(file.name)
      }
      setFileName(fileNameList.join(","))
    }
  };

  useEffect(() => {
    if (!file) {
      setFileName("")
    }
  }, [file]);

  return (
    <AppFieldWrapper label={label} required={required}>
      <input style={{ display: 'none' }} type="file" accept={accept} ref={fileInputRef} onChange={handleFile} multiple={multiple}/>
      <Button color="primary" variant="outlined" onClick={handleOpenFile}>ファイルを選択</Button>
      <span style={{marginLeft: 8}}>{fileName}</span>
    </AppFieldWrapper>
  );
};

const AppButton = ({ label, onClick, endIcon }) => {
  return (
    <AppFieldWrapper>
      <Button variant="outlined" endIcon={endIcon} onClick={onClick}>
        {label}
      </Button>
    </AppFieldWrapper>
    
  );
};

export { AppFieldWrapper, AppText, AppNumber, AppPassword, AppSelect, AppRadio, AppDate, AppDateTime, AppAttachment, AppMultipleSelect, AppDateRange, AppUpload, AppButton };
